<template>
  <b-modal
    id="modal-movement-route"
    v-model="show"
    :title="getTitle"
    size="xl"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <Form
        :data="detail"
        :id="id"
        @submit="submit"
        :is-update="isEdit"
        :is-copy="isCopy"
        @cancel="cancel"
        @change-status="showPopupChangeStatus"
        @set-loading="setLoading"
      />
    </b-overlay>
  </b-modal>
</template>
<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import { CHANGE_STATUS } from '@/core/services/store/movementRoute.module';
import { createNamespacedHelpers } from 'vuex';
import { SCHEDULE_TYPES } from '../../../../core/plugins/constants';
const { mapActions } = createNamespacedHelpers('movementRoute');
export default {
  name: 'ModalMovementRoute',
  props: {
    type: {
      type: String,
      default: MODAL_TYPE.CREATE,
    },
    id: {
      type: String,
      default: null,
    },
  },
  components: {
    Form: () => import('./Form.vue'),
  },
  data() {
    return {
      detail: null,
      loading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
    isEdit() {
      return this.type === MODAL_TYPE.EDIT;
    },
    isCopy() {
      return this.type === MODAL_TYPE.COPY;
    },
    getTitle() {
      if (this.isEdit) {
        return 'Chỉnh sửa lộ trình';
      } else if (this.isCopy) {
        return 'Sao chép lộ trình';
      }
      return 'Tạo lộ trình mới';
    },
  },
  created() {
    this.getDataPrepare();
  },
  methods: {
    ...mapActions({ CHANGE_STATUS }),
    resetModal() {
      this.$emit('reset');
    },
    async getDataPrepare(loading = true) {
      try {
        loading && (this.loading = true);
        const params = {
          id: this.id,
        };
        const { meta, data } = await this.$api.get(
          '/Agenda/PrepareCreateUpdateFormItem',
          {
            params,
          },
        );
        if (!meta.success) return;
        this.detail = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        loading && (this.loading = false);
      }
    },
    async submit(params) {
      if (this.type === MODAL_TYPE.CREATE) return this.create(params);
      params['id'] = this.id;
      return this.eddit(params);
    },
    async create(params) {
      try {
        this.loading = true;
        if (params.classify === SCHEDULE_TYPES.MOVEMENT_EXERCISES) {
          if (params.file) {
            const fileId = await this.uploadImage(params.file);
            if (!fileId) return;
            delete params.file;
            params['coverId'] = fileId;
          } else {
            params['coverId'] = this.detail.image.id;
          }
        }

        const { meta, data, error } = await this.$api.post('/Agenda', params);
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.cancel();
        this.$toastr.s({
          title: 'Thành công !',
          msg: `Tạo mới ${params.name} thành công`,
        });
        if (params.classify === SCHEDULE_TYPES.MOVEMENT_EXERCISES) {
          return this.$router.push({
            name: 'movement_route_detail',
            params: {
              id: data.id,
            },
          });
        }
        this.$router.push({
          name: 'agenda_detail',
          params: {
            id: data.id,
          },
        });
        return this.$emit('change-status');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async eddit(params) {
      try {
        this.loading = true;
        if (params.file) {
          const fileId = await this.uploadImage(params.file);
          if (!fileId) return;
          delete params.file;
          params['coverId'] = fileId;
        }

        const { meta, error, data } = await this.$api.put(
          `/Agenda/${this.id}`,
          params,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.cancel();
        this.$toastr.s({
          title: 'Thành công !',
          msg: `Chỉnh sửa ${params.name} thành công`,
        });

        if (
          ['agenda_detail', 'movement_route_detail'].includes(this.$route.name)
        ) {
          if (params.classify === SCHEDULE_TYPES.MOVEMENT_EXERCISES) {
            this.$router.push({
              name: 'movement_route_detail',
              params: {
                id: data.id,
              },
            });
          } else {
            this.$router.push({
              name: 'agenda_detail',
              params: {
                id: data.id,
              },
            });
          }
        }
        this.$emit('change-status');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },
    cancel() {
      this.$store.commit(`context/setModal`, false);
    },
    showPopupChangeStatus(status) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          status ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(status);
          }
        }.bind(this),
      );
    },
    async changeStatus(status) {
      this.loading = true;
      const params = {
        id: this.id,
        state: status,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.getDataPrepare();
        this.$emit('change-status');
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
      this.loading = false;
    },
    setLoading(state) {
      this.loading = state;
    },
  },
};
</script>
<style lang="scss" scope></style>
